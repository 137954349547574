.section-top {
    height: 816px;
    background: #f4f4f4;
    padding: 10px 0;
    position: relative;
    @include breakpoint(tablet) {
        height: auto;
        padding-bottom: 50px;
    }
    @include breakpoint(mobile) {
        height: auto;
        padding-bottom: 16px;
    }
    &__text {
        max-width: 40%;
        margin: 202px 0 0 0;
        @include breakpoint(monitor) {
            margin: 130px 0 0 0;
        }
        @include breakpoint(laptop) {
            max-width: 47%;
        }
        @include breakpoint(tablet) {
            max-width: 100%;
            margin-top: 461px;
        }
        h1 {
            font-family: $pop;
            margin: 0;
            font-size: 48px;
            line-height: 52px;
            font-weight: bold;
            @include breakpoint(laptop) {
                font-size: 38px;
                line-height: 42px;
            }
            @include breakpoint(tablet) {
                font-size: 28px;
                line-height: 36px;
            }
        }
        p {
            font-size: 16px;
            margin: 40px 0 42px 0;
            max-width: 380px;
            line-height: 24px;
            @include breakpoint(tablet) {
                margin: 22px 0 25px 0;
                max-width: 100%;
            }
        }
        .btn {
            margin: 0 7px 20px 0;
            @include breakpoint(tablet) {
                min-width: 207px;
                margin: 0 0 19px 0;
            }
            svg,
            img {
                margin: -9px 0 -5px 12px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    &__img {
        position: absolute;
        right: 0;
        left: 50%;
        top: 0;
        bottom: -79px;
        background: url("../images/top-bg.png");
        background-size: cover;
        @include breakpoint(tablet) {
            bottom: auto;
            height: 438px;
            overflow: hidden;
        }
        @include breakpoint(mobile) {
            left: 0;
        }
    }
    &__video {
        position: absolute;
        left: 50%;
        top: 188px;
        margin: 0 0 0 -119px;
        @include breakpoint(monitor) {
            top: 140px;
        }
        @include breakpoint(laptop) {
            max-width: 600px;
            margin: 0px 0 0 -59px;
        }
        @include breakpoint(tablet) {
            transform: translateX(-50%) !important;
            margin: 0;
            top: 87px;
            width: 100%;
            max-width: 540px;
        }
        @include breakpoint(mobile) {
            max-width: 320px;
            top: 167px;
        }
        img {
            max-width: 100%;
        }
        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 74px;
            height: 74px;
            @include breakpoint(mobile) {
                width: 34px;
                height: 34px;
            }
            content: "";
            * {
                transition: all 0.3s;
            }
        }
        &:hover {
            svg {
                * {
                    fill: #1ebdfe;
                }  
            }
        }
    }
    &__scroll {
        position: absolute;
        width: 1px;
        height: 173px;
        background: #1ebdfe;
        left: 150px;
        bottom: -79px;
        @include breakpoint(laptop) {
            display: none;
        }
        .mouse {
            position: absolute;
            width: 16px;
            height: 28px;
            border: 2px solid #1ebdfe;
            border-radius: 10px;
            top: -26px;
            left: -8px;
            &::after {
                position: absolute;
                width: 2px;
                height: 8px;
                background: #1ebdfe;
                border-radius: 150px;
                content: "";
                left: 5px;
                top: 4px;
                animation-name: scroll;
                animation-duration: 2.2s;
                animation-timing-function: cubic-bezier(.15,.41,.69,.94);
                animation-iteration-count: infinite;
            }
        }
    }
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

.section-imagination {
    padding: 156px 0 70px 0;
    position: relative;
    @include breakpoint(tablet) {
        padding: 70px 0;
    }
    @include breakpoint(mobile) {
        padding: 47px 0 17px 0;
    }
    h2 {
        font-family: $pop;
        font-size: 48px;
        line-height: 56px;
        margin: 0 0 0 0;
        max-width: 470px;
        @include breakpoint(laptop) {
            font-size: 38px;
            line-height: 42px;
        }
        @include breakpoint(mobile) {
            font-size: 28px;
            line-height: 36px;
            max-width: 100%;
        }
    }
    p {
        font-size: 28px;
        line-height: 36px;
        color: #565656;
        margin: 39px 0 0 0;
        max-width: 700px;
        @include breakpoint(laptop) {
            font-size: 20px;
            line-height: 24px;
            max-width: 500px;
        }
        @include breakpoint(tablet) {
            max-width: calc(100% - 380px);
        }
        @include breakpoint(mobile) {
            margin: 20px 0 0 0;
            font-size: 22px;
            line-height: 30px;
            max-width: 100%;
        }
    }
    img {
        position: absolute;
        left: 50%;
        top: 128px;
        margin-left: 119px;
        @include breakpoint(laptop) {
            margin-left: 0;
        }
        @include breakpoint(tablet) {
            max-width: 350px;
            top: 70px;
            margin-left: 20px;
        }
        @include breakpoint(mobile) {
            position: static;
            max-width: 290px;
            display: block;
            margin: 44px auto 0 auto;
        }
    }
}
.section-how-it-works {
    margin: 30px 0 0 0;
    position: relative;
    overflow: hidden;
    @include breakpoint(laptop) {
        background-size: 120% auto;
    }
    @include breakpoint(tablet) {
        background-size: 80% auto;
    }
    @include breakpoint(mobile) {
        margin: 0 0 0 0;
        background-size: 367px auto;
    }
    .bg {
        background: url("../images/person-laptop.png") right bottom no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        left: 0;
        z-index: -1;
        @include breakpoint(laptop) {
            background-size: 120% auto;
        }
        @include breakpoint(tablet) {
            background-size: 60% auto;
        }
        @include breakpoint(mobile) {
            background-size: 367px auto;
        }
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        height: 1270px;
        @include breakpoint(tablet) {
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
            height: 900px;
        }
        @include breakpoint(mobile) {
            height: auto;
            padding-bottom: 242px;
        }
    }
    .col1 {
        width: 32%;
        padding-right: 20px;
        @include breakpoint(tablet) {
            width: 100%;
        }
        @include breakpoint(mobile) {
            padding-right: 0;
        }
        h2 {
            font-family: $pop;
            font-size: 36px;
            margin: 0 0 18px 0;
            @include breakpoint(mobile) {
                font-size: 24px;
                margin: 12px 0 0 0;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        color: #565656;
        margin: 0 36px 24px 0;
        @include breakpoint(mobile) {
            margin: 12px 0 24px 0;
        }
    }
    h3 {
        font-family: $pop;
        font-size: 20px;
        line-height: 26px;
        margin: 0 29px 12px 0;
    }
    img {
        margin: 8px 0 11px 0;
    }
    .col2 {
        width: 23.4%;
        @include breakpoint(tablet) {
            width: 33.333%;
        }
        @include breakpoint(mobile) {
            width: 100%;
            p {
                max-width: 80%;
            }
        }
    }
    .col3 {
        width: 24%;
        @include breakpoint(tablet) {
            width: 33.333%;
        }
        @include breakpoint(mobile) {
            width: 100%;
            p {
                max-width: 80%;
            }
        }
    }
    .col4 {
        width: 20.4%;
        @include breakpoint(tablet) {
            width: 33.333%;
        }
        @include breakpoint(mobile) {
            width: 100%;
            p {
                max-width: 80%;
            }
        }
    }
    .btn-wrap {
        margin: 0 0 0 auto;
        position: absolute;
        bottom: 481px;
        right: 10px;
        @include breakpoint(tablet) {
            bottom: 40px;
        }
        @include breakpoint(mobile) {
            text-align: right;
            bottom: 14px;
            text-align: right;
        }
        .btn {
            margin: 0 7px 0 0;
            @include breakpoint(mobile) {
                margin: 18px 7px 0 0;
            }
            svg,
            img {
                margin: -9px 0 -5px 12px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

.section-benefits {
    position: relative;
    margin: -405px 0 0 0;
    padding: 1px 0 0;
    @include breakpoint(tablet) {
        margin: 0;
    }
    &__img {
        background: url("../images/person-handshake.png") center;
        background-size: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: calc(50% + 220px);
        @include breakpoint(laptop) {
            right: auto;
            width: 400px;
        }
        @include breakpoint(tablet) {
            display: none;
        }
    }
    &__text {
        background: #f4f4f4;
        padding: 59px 60px 79px 111px;
        margin-left: 350px;
        @include breakpoint(laptop) {
            margin-right: -20px;
            padding: 30px 60px 49px 90px;
        }
        @include breakpoint(tablet) {
            margin-left: -20px;
            padding: 30px 20px 30px 20px;
        }
        @include breakpoint(mobile) {
            padding: 35px 20px 48px 20px;
        }
        h2 {
            font-family: $pop;
            font-size: 24px;
            font-weight: normal;
            margin-bottom: 69px;
            @include breakpoint(mobile) {
                margin: 26px 0 27px 0;
            }
            span {
                font-weight: bold;
                @include breakpoint(mobile) {
                    display: inline-block;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: 40%;
                margin-bottom: 24px;
                @include breakpoint(tablet) {
                    width: 47%;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                    margin: 0 0 12px 0;
                }
                &:nth-child(3),
                &:nth-child(1) {
                    p {
                        margin-right: -20px;
                    }
                }
                img {
                    margin: 0 0 0 0;
                }
                h3 {
                    font-size: 24px;
                    font-family: $pop;
                    color: #282828;
                    margin: 11px 0 0 0;
                    line-height: 30px;
                    @include breakpoint(mobile) {
                        margin: 11px 105px 0 0;
                        line-height: 25px;
                        font-size: 20px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 20px;
                    color: #565656;
                    margin: 12px 15px 24px 0;
                }
            }
        }
    }
    .section-title {
        h2 {
            @media all and (min-width: 1024px) {
                transform: rotate(-90deg);
                z-index: 10;
                position: absolute;
                z-index: 10;
                left: 50%;
                top: -55px;
                margin-left: -689px;
                @media all and (max-width: 1260px) {
                    left: -45px;
                    margin-left: 0;
                }
            }
        }
        @include breakpoint(tablet) {
            text-align: left;
            display: block;
            h2 {
                font-size: 28px;
            }
        }
    }
    .btn {
        @media all and (min-width: 1024px) {
            position: absolute;
            bottom: 100px;
            left: 50%;
            margin-left: -600px;
            @media all and (max-width: 1260px) {
                left: 20px;
                margin-left: 0;
            }
        }
        @include breakpoint(tablet) {
            display: inline-block;
        }
    }
}

.section-title {
    text-align: center;
    @include breakpoint(mobile) {
        text-align: left;
    }
    h2 {
        font-family: $pop;
        font-size: 48px;
        font-weight: bold;
        margin: 0 0 0 0;
        @include breakpoint(laptop) {
            font-size: 38px;
            line-height: 42px;
        }
        @include breakpoint(mobile) {
            font-size: 28px;
        }
    }
    p {
        color: #565656;
        font-size: 16px;
        line-height: 24px;
        margin: 14px 0;
    }
}

.border-animate {
    position: relative;
    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        height: 2px;
        background: #1ebdfe;
        transition: all 1s;
        width: 0;
    }
    &.aos-animate {
        &::after {
            width: 100%;
        }
    }
}

.section-integration {
    padding: 69px 0 76px 0;
    text-align: center;
    @include breakpoint(mobile) {
        padding: 40px 0;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        margin: 58px 0 67px 0;
        position: relative;
        overflow: hidden;
        text-align: left;
        @include breakpoint(mobile) {
            flex-wrap: wrap;
        }
    }
    &__left {
        width: 40%;
        @include breakpoint(mobile) {
            width: 100%;
        }
        h3 {
            font-size: 24px;
            font-weight: bold;
            font-family: $pop;
            color: #282828;
            margin: 0 0 0 0;
        }
        p {
            font-size: 16px;
            color: #565656;
            line-height: 24px;
            margin: 20px 0 0 0;
        }
    }
    .or-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-family: $pop;
        font-weight: bold;
        font-size: 24px;
        color: #1ebdfe;
        padding: 12px 0;
        @include breakpoint(mobile) {
            display: block;
            position: static;
            transform: none;
            text-align: center;
            padding: 40px 0;
            width: 100%;
        }
        &::after {
            position: absolute;
            left: 50%;
            top: 100%;
            height: 1000px;
            width: 1px;
            content: "";
            background: #e1e1e1;
        }
        &::before {
            position: absolute;
            left: 50%;
            bottom: 100%;
            height: 1000px;
            width: 1px;
            content: "";
            background: #e1e1e1;
        }
    }
}

.section-process {
    background: #2e2e2e;
    padding: 65px 0 83px 0;
    color: #FFF;
    text-align: center;
    @include breakpoint(mobile) {
        padding: 36px 0 62px 0;
        position: relative;
    }
    .section-title {
        margin: 0 0 0 0;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        text-align: center;
        flex-wrap: wrap;
        margin: 117px 15px 57px 25px;
        @include breakpoint(mobile) {
            margin: 29px 0 50px 0;
            display: block;
        }
        .item {
            width: 20%;
            text-align: center;
            position: relative;
            @include breakpoint(mobile) {
                width: 100%;
                text-align: left;
                padding: 0 90px 0 22px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 140px;
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
            &.line {
                &::before {
                    position: absolute;
                    left: calc(50% + 36px);
                    top: 124px;
                    height: 13px;
                    background: url("../images/svg/arrow.svg") right center no-repeat;
                    background-size: auto 100%;
                    content: "";
                    width: 0;
                    transition: all .5s 0.2s;
                    @include breakpoint(tablet) {
                        width: 90%;
                    }
                    @include breakpoint(mobile) {
                        left: -5px;
                        top: 45px;
                        width: 13px;
                        height: 155px;
                        background: url("../images/svg/arrow-v-mobile.svg") center bottom no-repeat;
                        transition: none;
                        background-size: 100% auto;
                    }
                }
                &.aos-animate {
                    &::before {
                        width: 100%;
                    }
                }
                &:nth-child(1) {
                    &::before {
                        transition-delay: 0.2;
                    }
                }
                &:nth-child(2) {
                    &::before {
                        @include breakpoint(mobileonly) {
                            top: 60px;
                        }
                        transition-delay: 1.5s;
                    }
                }
                &:nth-child(3) {
                    &::before {
                        transition-delay: 2.5s;
                    }
                }
            }
            &::after {
                border: 5px solid #ffe42c;
                border-radius: 50%;
                position: absolute;
                top: 117px;
                left: 50%;
                width: 16px;
                height: 16px;
                content: "";
                transform: translateX(-50%);
                @include breakpoint(mobile) {
                    top: 17px;
                    left: -8px;
                    transform: translateX(0);
                    width: 12px;
                    height: 12px;
                    border-width: 4px;
                    top: 50%;
                    margin-top: -13px;
                }
            }
            .img {
                height: 60px;
                line-height: 60px;
                margin-bottom: 124px;
                @include breakpoint(mobile) {
                    margin-bottom: 0;
                }
                img {
                    display: inline-block;
                    vertical-align: middle;
                    @include breakpoint(mobile) {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            p {
                font-size: 15px;
                line-height: 20px;
                @include breakpoint(mobile) {
                    text-align: left;
                    width: calc(100% - 59px);
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
        .item-bottom {
            width: 54.4%;
            text-align: center;
            margin: 93px 107px 0 auto;
            position: relative;
            @include breakpoint(laptop) {
                margin: 93px 84px 0 auto;
            }
            @include breakpoint(tablet) {
                margin: 93px 54px 0 auto;
            }
            @include breakpoint(mobile) {
                position: absolute;
                top: 121px;
                right: 0;
                margin: 0 0 0 0;
                width: 84px;
            }
            &::after {
                position: absolute;
                bottom: 126%;
                left: 0;
                right: 0;
                border: 3px solid #FFF;
                border-top: 0;
                border-radius: 0 0 30px 30px;
                content: "";
                height: 67px;
                @include breakpoint(mobile) {
                    left: -20px;
                    top: 12px;
                    bottom: 0;
                    border: 2px solid #FFF !important;
                    border-left: 0 !important;
                    border-radius: 0 30px 30px 0 !important;
                    right: 100%;
                    height: 422px;
                }
            }
            img {
                margin: 0 0 -7px 0;
                @include breakpoint(mobile) {
                    margin: 165px 0 -7px 0;
                }
            }
            p {
                font-size: 15px;
                line-height: 20px;
                @include breakpoint(mobile) {
                    font-size: 14px;
                }

            }
        }
    }
}

.section-faq {
    padding: 63px 0 89px 0;
    background: #f4f4f4;
    @include breakpoint(mobile) {
        padding: 34px 0 38px 0;
        background: #FFF;
    }
}

.faq {
    max-width: 990px;
    margin: 35px auto 0 auto;
    @include breakpoint(mobile) {
        margin: 30px -2px 0 -4px ;
    }
    &__item {
        background: #FFF;
        padding: 0 0 0 0;
        margin: 0 0 5px 0;
        @include breakpoint(mobile) {
            background: #f8f8f8;
        }
        &.open {
            .faq__btn {
                &::after {
                    opacity: 0;
                }
            }
        }
    }
    &__btn {
        font-size: 16px;
        padding: 15px 20px;
        font-weight: bold;
        font-family: $pop;
        cursor: pointer;
        position: relative;
        @include breakpoint(mobile) {
            font-size: 16px;
            padding: 15px 50px 15px 16px;
            line-height: 24px;
        }
        &::after {
            position: absolute;
            background: #1ebdfe;
            width: 3px;
            height: 17px;
            right: 27px;
            top: 19px;
            content: "";
            transition: all 0.3s;
        }
        &::before {
            position: absolute;
            background: #1ebdfe;
            width: 17px;
            height: 3px;
            right: 20px;
            top: 26px;
            content: "";
        }
    }
    &__toggle {
        padding: 0 20px 1px 21px;
        display: none;
        @include breakpoint(mobile) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.text-style {
    p {
        font-size: 15px;
        color: #565656;
        margin: 0px 0 28px 0;
    }
    ul {
        font-size: 15px;
        color: #565656;
        margin: 0 0 28px 37px;
        padding: 0 0 0 0;
        list-style: none;
        li {
            margin-bottom: 4px;
            position: relative;
            &::after {
                position: absolute;
                left: -14px;
                top: 4px;
                font-family: "Font Awesome 5 Pro";
                content: "\f054";
                font-size: 10px;
                color: #1fa6fd;
                font-weight: 900;
            }
        }
    }
}

.section-price {
    background: #2e2e2e;
    padding: 73px 0 83px 0;
    color: #FFF;
    text-align: center;
    @include breakpoint(mobile) {
        padding: 40px 0;
    }
    .section-title {
        margin: 0 0 0 0;
    }
    &__content {
        text-align: left;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 35px 0 50px 0;
        @include breakpoint(mobile) {
            margin: 10px 0 20px 0;
        }
    }
    &__col {
        width: 48.5%;
        @include breakpoint(mobile) {
            width: 100%;
        }
        p {
            font-size: 15px;
            margin: 17px 0 22px 0;
            max-width: 475px;
            line-height: 22px;
            letter-spacing: 0.5px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 31px 0 0 0;
            padding: 0;
            list-style: none;
            li {
                width: 50%;
                @include breakpoint(tablet) {
                    width: 100%;
                }
                background: url("../images/svg/check-icon.svg") 6px 5px no-repeat;
                background-size: 15px 14px;
                padding: 0 0 10px 48px;
                margin-bottom: 24px;
            }
        }
    }
}

.section-contact {
    padding: 57px 0 76px 0;
    @include breakpoint(mobile) {
        padding: 35px 0 42px 0;
    }
    .section-title {
        margin: 0 0 48px 0;
        @include breakpoint(mobile) {
            margin: 0 0 22px 0;
        }
    }
    h3 {
        font-family: $pop;
        font-weight: bold;
        font-size: 24px;
        margin: 0 0 26px 0;
    }
    &__col1 {
        display: inline-block;
        width: 34%;
        @include breakpoint(tablet) {
            width: 45%;
        }
        @include breakpoint(mobile) {
            width: 100%;
        }
        p {
            font-size: 15px;
            line-height: 22px;
            color: #565656;
            margin: 0 0 22px 0;
            a {
                color: #1fa6fd;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &__col2 {
        display: inline-block;
        width: 60%;
        vertical-align: top;
        @include breakpoint(tablet) {
            width: 49%;
        }
        @include breakpoint(mobile) {
            width: 100%;
        }
    }
    &__col3 {
        margin: 23px 0 0 0;
    }
    &__boxs {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__box {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 43%;
        margin-bottom: 20px;
        @include breakpoint(tablet) {
            width: 100%;
            margin-bottom: 20px;
        }
        @include breakpoint(mobile) {
            margin: 11px 0 21px 0;
        }
        > div {
            width: calc(100% - 118px);
            h4 {
                font-family: $pop;
                font-size: 18px;
                margin: 0;
                white-space: nowrap;
            }
            p {
                margin: 5px 0 -6px 0;
                font-size: 15px;
                line-height: 22px;
                color: #565656;
                a {
                    color: #1fa6fd;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .req {
        color: #ff0000;
    }
    p {
        width: 100%;
    }
    .form-wrap {
        width: 48.7%;
        margin-bottom: 16px;
        @include breakpoint(mobile) {
            width: 100%;
            margin-bottom: 11px;
        }
        br {
            display: none;
        }
        &--full {
            width: 100%;
            @include breakpoint(mobile) {
                margin: 0;
            }
        }
        &__label {
            text-transform: uppercase;
            font-size: 13px;
            color: #565656;
            font-weight: bold;
            display: block;
            margin: 5px 0 8px 0;
        }
        &__input {
            background: #efefef;
            padding: 0 15px;
            border: none;
            height: 46px;
            width: 100%;
        }
        &__textarea {
            background: #efefef;
            padding: 15px;
            border: none;
            height: 46px;
            width: 100%;
            height: 167px;
        }
    }
    .form-checkbox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__input {
            width: 27px;
            height: 27px;
            background: #efefef;
            position: relative;
            cursor: pointer;
            label {
                width: 27px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 0;
                background: url("../images/svg/check-icon.svg") center no-repeat;
                background-size: 12px 12px;
                opacity: 0;
                cursor: pointer;
            }
            input {
                opacity: 0;
                &:checked + label {
                    opacity: 1;
                }
            }
        }
        &__label {
            width: calc(100% - 49px);
            font-size: 12px;
            color: #575757;
            line-height: 14px;
            cursor: pointer;
            margin: 5px 0 0 0;
            cursor: pointer;
            @include breakpoint(mobile) {
                margin: 1px 0 0 0;
            }
            a {
                color: #1fa6fd;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .wpcf7-list-item {
            margin: 0;
            input {
                opacity: 0;
                position: absolute;
                &:checked + .wpcf7-list-item-label {
                    &::after {
                        opacity: 1;
                        background-image: url("../images/svg/check-icon.svg");
                    }
                }
            }
        }
        .wpcf7-list-item-label {
            font-size: 12px;
            color: #575757;
            line-height: 14px;
            cursor: pointer;
            margin: 5px 0 0 0;
            padding-left: 45px;
            cursor: pointer;
            display: block;
            position: relative;
            &::after {
                width: 27px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 0;
                background: #efefef center no-repeat;
                background-size: 12px 12px;
                cursor: pointer;
                content: "";
            }
            a {
                color: #1fa6fd;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    button {
        width: 100%;
        text-align: center;
        border: none;
        font-size: 16px;
        padding: 25px 0;
    }
    .wpcf7-not-valid-tip,
    label.error {
        color: #ff0000;
        display: block;
        font-size: 12px;
        margin: 8px 0 -3px 0;
        width: 100%;
    }
}
div.wpcf7-response-output {
    width: 100%;
    text-align: center;
    padding: 15px !important;
    font-size: 14px !important;
    margin: 0 !important;
}