.loader {
    background: rgba(0,0,0,0.82);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 10000;
    padding: 0 15px;
    &__inner {
        width: 100%;
        max-width: 590px;
        margin: 0 auto;
    }
    &__line {
        height: 7px;
        width: 100%;
        position: relative;
        background: #FFF;
        .loader-stat {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: #ffe42c;
        }
    }
    &__text {
        color: #FFF;
        font-family: $pop;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin: 15px 0;
    }
}

.view3d {
    position: relative;
    height: 100vh;
    width: 100vw;
    border-bottom: 5px solid #282828;
    overflow: hidden;
    background: #f4f4f4;
    @include breakpoint(mobile) {
        @media (orientation: landscape) {
            border-left: 5px solid #282828;
            border-bottom: 0;
        }
    }
    .content-toggle {
        display: none;
        @media all and (max-width: 1280px){
            display: block;
            position: absolute;
            right: 15px;
            top: 15px;
            width: 44px;
            height: 44px;
            background: #ffe42c;
            z-index: 120;
            svg {
                max-width: 28px;
                margin: 7px 0 0 9px;
                * {
                    fill: #000;
                }
            }
            span {
                background: #282828;
                width: 28px;
                height: 5px;
                position: absolute;
                transition: all 0.3s;
                opacity: 0;
                &:nth-child(1) {
                    top: 12px;
                    left: 8px;
                }
                &:nth-child(2) {
                    top: 21px;
                    left: 8px;
                }
                &:nth-child(3) {
                    top: 30px;
                    left: 8px;
                }
            }
            &.open {
                svg {
                    opacity: 0;
                }
                span {
                    opacity: 1;
                    &:nth-child(1) {
                        transform: rotate(45deg);
                        top: 19px;
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        top: 19px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .logo {
        position: absolute;
        left: 36px;
        top: 33px;
        @media all and (max-width: 1280px){
            left: 20px;
            top: 15px;
        }
        @include breakpoint(mobile) {
            left: 23px;
            top: 26px;
            @media (orientation: landscape) {
                left: auto;
                right: 23px;
                bottom: 20px;
                top: auto;
            }
        }
        img {
            width: 208px;
            height: 32px;
            @include breakpoint(laptop) {
                width: 165px;
                height: 27px;
            }
            @include breakpoint(mobile) {
                width: 125px;
                height: 21px;            
            }
        }
    }
    .house {
        position: absolute;
        top: 0;
        left: 0;
        right: 52px;
        bottom: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(monitor) {
            right: 330px;
            img {
                max-width: 90%;
                max-height: 100%;
            }
        }
        @media all and (max-width: 1280px){
            right: 0;
            top: 70px;
        }
        @include breakpoint(mobile) {
            @media (orientation: landscape) {
                top: 49px;
                bottom: 35px;
            }
        }
    }
    .right-content {
        position: absolute;
        top: 11px;
        right: 10px;
        bottom: 10px;
        width: 336px;
        @media all and (max-width: 1280px){
            top: 70px;
            left: 0;
            right: 0;
            width: auto;
            bottom: 0;
            max-height: calc(100vh - 70px);
            background: #FFF;
            padding-bottom: 60px;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            z-index: 100;
            padding-bottom: 0;
            @media (orientation: landscape) {
                top: 0;
                max-height: calc(100vh);
            }
            &.open {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }
        }
        .banner-question {
            background-size: 325px auto;
            background-position: right bottom;
            @media all and (max-width: 1280px) {
                background-image: url("../images/person-laptop.png");
            }
        }
    }
}

.nav-bottom {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    background: #282828;
    padding: 16px 10px;
    .active-svg {
        display: none;
    }
    .active {
        svg {
            display: none;
        }
        .active-svg {
            display: block;
        }
    }
    @include breakpoint(mobile) {
        width: 100%;
        max-width: 215px;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        @media (orientation: landscape) {
            left: -5px;
            transform: translateY(-50%);
            top: 50%;
            bottom: auto;
            width: auto;
            padding: 0 6px;
        }
    }
    &::after {
        position: absolute;
        width: 0;
        height: 0;
        content: "";
        left: -59px;
        bottom: -63px;
        border-top: 60px solid transparent;
        border-bottom: 60px solid transparent;
        border-right: 60px solid #282828;
        @include breakpoint(mobile) {
            @media (orientation: landscape) {
                left: -44px;
                bottom: -80px;
                transform: rotate(45deg);
            }
        }
    }
    &::before {
        position: absolute;
        width: 0;
        height: 0;
        content: "";
        right: -59px;
        bottom: -63px;
        border-top: 60px solid transparent;
        border-bottom: 60px solid transparent;
        border-right: 60px solid #282828;
        transform: rotate(180deg);
        @include breakpoint(mobile) {
            @media (orientation: landscape) {
                right: 0px;
                top: -60px;
            }
        }
    }
    a {
        display: inline-block;
        vertical-align: middle;
        margin: 0 12px;
        padding: 0 5px;
        @include breakpoint(mobile) {
            margin: 0 8px;
            @media (orientation: landscape) {
                margin: 15px 0;
                display: block;
            }
        }
        &.play-button {
            svg {
                width: 20px;
                height: 22px;
            }
        }
        &.photo-button {
            svg {
                width: 27px;
                height: 22px;
            }
        }
        &.fullscreen-button {
            svg {
                width: 24px;
                height: 22px;
            }
        }
        &.info-button {
            svg {
                width: 24px;
                height: 22px;
            }
        }
        &:hover {
            svg {
                fill: #FFF;
                *{
                    fill: #FFF;
                }
            }
        }
    }
    /* Add this attribute to the element that needs a tooltip */
    [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  
  /* Hide the tooltip content by default */
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  
  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    top: -65px;
    left: 50%;
    margin-bottom: 5px;
    padding: 10px 15px;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #282828;
    color: #e2e2e2;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    @include breakpoint(mobile) {
        display: none !important;
    }
  }
  
  /* Triangle hack to make tooltip look like a speech bubble */
  [data-tooltip]:after {
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    border-top: 10px solid #282828;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }
  
  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
}

.earth-dir {
    position: absolute;
    left: 37px;
    bottom: 35px;
    transform: rotate(75deg);
    @include breakpoint(mobile) {
        left: 23px;
        bottom: 66px;
        @media (orientation: landscape) {
            bottom: 15px;
            left: 36px;
        }
    }
    img {
        width: 28px;
        height: 28px;
        @include breakpoint(mobile) {
            width: 24px;
            height: 24px;
        }
    }
}

.mouse-legend {
    position: absolute;
    bottom: 93px;
    right: 418px;
    padding: 7px 23px 24px 21px;
    background: #323232;
    @media all and (max-width: 1280px){
        right: 30px;
        bottom: 50px;
    }
    @include breakpoint(mobile) {
        display: none;
    }
    &__item {
        display: flex;
        align-items: center;
        color: #FFF;
        font-family: $pop;
        font-size: 13px;
        font-weight: 500;
        margin: 19px 0 0 0;
        img {
            width: 15px;
            height: 24px;
            margin: 0 12px 0 0;
        }
    }
    .close {
        position: absolute;
        right: -5px;
        top: -20px;
        width: 14px;
        height: 14px;
        &::after,
        &::before {
            background: #282828;
            content: "";
            width: 2px;
            height: 15px;
            position: absolute;
        }
        &::after {
            transform: rotate(45deg);
        }
        &::before {
            transform: rotate(-45deg);
        }
    }
}

[data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
  
  .simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }
  
  .simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0;
  }
  
  .simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }
  
  .simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
    width: auto;
    visibility: visible;
    max-width: 100%; /* Not required for horizontal scroll to trigger */
    max-height: 100%; /* Needed for vertical scroll to trigger */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .simplebar-content-wrapper::-webkit-scrollbar,
  .simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .simplebar-content:before,
  .simplebar-content:after {
    content: ' ';
    display: table;
  }
  
  .simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
  }
  
  .simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0;
  }
  
  .simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }
  
  .simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }
  
  [data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
  }
  
  [data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
  }
  
  .simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;
  }
  
  .simplebar-scrollbar:before {
    position: absolute;
    content: '';
    background: black;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  
  .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 0.5;
    transition: opacity 0s linear;
  }
  
  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
  }
  
  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
  }
  
  .simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px;
  }
  
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
  }
  
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
  }
  
  /* Rtl support */
  [data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
  }
  
  .hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  
  .simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .scroll-beatifull {
      overflow: auto;
      height: 100%;
  }
  .simplebar-track.simplebar-vertical {
      right: auto;
      left: 0;
      width: 4px;
      background: #e4e4e4;
  }
  .simplebar-scrollbar:before {
      opacity: 1 !important;
      background: #bdbdbd !important;
      border-radius: 0 !important;
      left: 0;
      right: 0;
  }
  .simplebar-wrapper {
      margin-left: 15px;
  }
  .simplebar-content {
      padding-left: 15px !important;
      @include breakpoint(tablet) {
          padding-left: 0 !important;
      }
  }

  .house-info {
      background: #282828;
      padding: 24px 45px 6px 23px;
      color: #FFF;
      overflow: hidden;
      .desc {
          font-size: 11px;
          line-height: 14px;
          margin: 19px -17px 19px 0;
          color: #aaaaaa;
      }
      &__title {
          h2 {
              font-size: 20px;
              font-weight: bold;
              font-family: $pop;
              margin: 0 0 0 0;
          }
          p {
              font-size: 16px;
              color: #aaaaaa;
              margin: 3px 0 0 0;
          }
      }
      &__list {
          h3 {
              margin: 18px 0 18px 0;
              font-size: 16px;
              font-weight: bold;
              font-family: $pop;
          }
          .item {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #3e3e3e;
              color: #aaaaaa;
              font-size: 14px;
              padding: 0 0 12px 0;
              margin: 0 0 10px 0;
              cursor: pointer;
              .left {
                  padding: 0 0 0 41px;
                  background: url("../images/svg/play.svg") left center no-repeat;
                  background-size: 18px 12px;
              }
              .right {
                  font-size: 13px;
              }
              &:hover {
                  color: #FFF;
                  .left {
                      background-image: url("../images/svg/play-white.svg");
                  }
              }
          }
      }
  }

  .house-contact {
      background: #FFF;
      overflow: hidden;
      margin: 12px 0 0 0;
    padding: 18px 28px 20px 26px;
      &__title {
          font-family: $pop;
          font-size: 16px;
          font-weight: bold;
          margin:  0 0 0 0;
      }
      .banner-question {
          &__person {
              margin: 16px 0;
              img {
                  width: 64px;
              }
              > div {
                  width: calc(100% - 80px);
                  h4 {
                      font-size: 14px;
                  }
              }
          }
      }
      .form {
          .form-wrap {
              margin-bottom: 4px;
              &__label {
                  font-size: 11px;
                  margin: 5px 0 5px 0;
              }
              &__input {
                  height: 35px;
              }
              &__textarea {
                  height: 120px;
              }
          }
          .form-checkbox {
              margin: 7px 0 0 0;
          }
          button {
              padding: 13px 0;
              font-size: 14px;
          }
      }
  }