.search-list-build {
    margin-top: 103px;
    position: relative;
    margin-bottom: 54px;
    background: #f5f5f5;
    max-height: 100vh;
    @media all and (max-width: 1400px) {
    }
    &--floor {
        @media all and (max-width: 1400px) {
            max-height: 672px;
        }
    }
    @include breakpoint(laptop) {
        margin-top: 82px;
    }
    @include breakpoint(mobile) {
        display: none;
    }
    .back-viz {
        position: absolute;
        right: 50px;
        top: 135px;
        @media all and (max-width: 1400px) {
            right: 20px;
            top: 20px;
        }
    }
    img {
        width: 100% !important;
    }
    * > {
        margin: 0 auto;
    }
    &__house {
        @media all and (max-width: 1400px) and (min-width: 1170px) {
            margin-top: -80px;
        }
        .maphilighted {
            @media all and (max-width: 1400px) and (min-width: 1170px) {
                // width: 50% !important;
            }
        }
    }
    &__inner {
        max-width: 790px;
        margin: 0 auto;
        padding: 165px 0;
        @media all and (max-width: 1400px) {
            padding: 60px 0;
            margin: 0 auto;
        }
        @include breakpoint(tablet) {
            max-width: 500px;
            padding: 100px 0 70px 0;
        }
    }
    &__tooltip {
        position: absolute;
        right: 311px;
        bottom: 60px;
        @media all and (max-width: 1400px) {
            bottom: 70px;
            right: 280px;
        }
        @include breakpoint(mobile) {
            display: none;

        }
        img {
            width: 42px !important;
        }
        .tooltip {
            position: absolute;
            bottom: calc(100% + 26px);
            left: -22px;
            width: 320px;
            background: #282828;
            padding: 10px 12px 14px 15px;
            color: #e2e2e2;
            font-size: 13px;
            line-height: 18px;
            &::after {
                position: absolute;
                left: 43px;
                bottom: -13px;
                content: "";
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 15px solid #282828;
            }
            strong {
                font-weight: 600;
            }
            p {
                margin: 0;
            }
            .close {
                position: absolute;
                right: 0px;
                top: -24px;
                width: 14px;
                height: 14px;
                &::after,
                &::before {
                    background: #282828;
                    content: "";
                    width: 2px;
                    height: 15px;
                    position: absolute;
                }
                &::after {
                    transform: rotate(45deg);
                }
                &::before {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.search-list-houses {
    padding-bottom: 54px;
    @include breakpoint(mobile) {
        padding-top: 120px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .left-content {
        width: calc(100% - 307px);
        @include breakpoint(laptop) {
            width: 100%;
        }
    }
    .right-content {
        width: 278px;
        @include breakpoint(laptop) {
            width: 100%;
            max-width: 300px;
            margin: 20px auto;
        }
    }
    .search-item {
        display: flex;
        flex-wrap: wrap;
        border: 2px solid #e4e4e4;
        padding: 17px 34px 16px 15px;
        margin: 0 0 9px 0;
        transition: all 0.3s;
        position: relative;
        &:hover {
            border-color: #282828;
            // padding: 16px 33px 15px 14px;
            &::after {
                opacity: 1;
            }
        }
        &::after {
            position: absolute;
            left: -2px;
            top: -2px;
            right: -2px;
            bottom: -2px;
            pointer-events: none;
            content: "";
            border: 3px solid #282828;
            opacity: 0;
            transition: all 0.3s;
        }
        &__img {
            width: 24%;
            text-align: center;
            position: relative;
            @include breakpoint(mobile) {
                width: 40%;
            }
            @include breakpoint(mobileonly) {
                width: 100%;
            }
            .btn-3d {
                right: 20px;
                top: 9px;
                position: absolute;
            }
        }
        &__desc {
            width: 45%;
            margin-left: 4.7%;
            @include breakpoint(mobile) {
                width: 60%;
                margin-left: 0;
                padding-left: 20px;
            }
            @include breakpoint(mobileonly) {
                width: 100%;
                padding-left: 0;
            }
            .desc-list {
                display: flex;
                flex-wrap: wrap;
                margin: 29px 0 0 0;
                &__item {
                    margin: 0 0 11px 0;
                    width: 62%;
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    @include breakpoint(mobileonly) {
                        width: 100% !important;
                    }
                    &:nth-child(2n) {
                        width: 38%;
                    }
                    .left {
                        width: 56%;
                    }
                    .right {
                        width: 44%;
                        font-weight: bold;
                    }
                }
            }
        }
        &__title {
            font-size: 24px;
            margin: 17px 0 0 0;
            font-weight: normal;
            strong {
                font-weight: 600;
            }
            .status {
                border: 2px solid transparent;
                text-transform: uppercase;
                padding: 4px 8px;
                display: inline-block;
                vertical-align: middle;
                font-size: 11px;
                font-weight: bold;
                margin: -4px 0 0 19px;
                &.free {
                    border-color: #1ebdfe;
                    color: #1ebdfe;
                }
                &.sold {
                    border-color: #ff6b6b;
                    color: #ff6b6b;
                }
                @include breakpoint(mobileonly) {
                    margin: 5px 0 0 0;
                    display: block;
                    max-width: 100px;
                    text-align: center;
                }
            }
        }
        &__price {
            width: 26%;
            text-align: right;
            @include breakpoint(mobile) {
                width: 100%;
                text-align: center;
            }
            .price {
                margin: 24px 0 23px 0;
                strong {
                    font-family: $pop;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 24px;
                }
                span {
                    display: block;
                    font-family: $pop;
                    font-weight: bold;
                    font-size: 16px;
                    margin: 0 0 0 0;
                }
            }
        }
    }
}
.btn:hover {
    .btn-3d.btn-3d-icon {
        background-image: url("../images/animate3d_hover.gif");
    }
}
.btn-3d {
    width: 55px;
    height: 55px;
    position: relative;
    background-color: #ffe42c !important;
    background-image: url("../images/animate3d.gif");
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    transition: all 0.3s;
    &.btn-3d-icon {
        display: inline-block;
        vertical-align: middle;
        margin: -16px -20px -16px -5px;
        background-color: transparent !important;
    }
    &__item1 {
        background: url("../images/svg/3d-icon-2.svg") no-repeat;
        width: 12px;
        height: 8px;
        background-size: 100% auto;
        position: absolute;
        left: 24px;
        top: 23px;
        display: none;
    }
    &__item2 {
        background: url("../images/svg/3d-icon-1.svg") no-repeat;
        width: 14px;
        height: 36px;
        background-size: 100% auto;
        position: absolute;
        left: 26px;
        top: 11px;
        animation: animate_3d 3s infinite;
        display: none;
    }
    &__item3 {
        background: url("../images/svg/3d-icon-3.svg") no-repeat;
        width: 37px;
        height: 13px;
        background-size: auto 100%;
        position: absolute;
        left: 11px;
        top: 23px;
        animation: animate_3d 3s infinite;
        display: none;
    }
}
@keyframes animate_3d {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.banner-question {
    background: #f5f5f5 url("../images/banner-bg.png") center bottom no-repeat;
    background-size: 100% auto;
    padding: 40px 23px 218px 23px;
    &__title {
        margin: 0;
        padding: 0;
        font-family: $pop;
        font-size: 28px;
        line-height: 32px;
    }
    &__person {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 22px 0 23px 0;
        img {
            width: 55px;
        }
        > div {
            width: calc(100% - 70px);
            h4 {
                font-family: $pop;
                font-size: 16px;
                margin: 0;
            }
            p {
                margin: 0px 0 -6px 0;
                font-size: 15px;
                line-height: 22px;
                color: #565656;
                a {
                    color: #1fa6fd;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.banner-question-vert {
    background: #f5f5f5 url("../images/person-laptop.png") right bottom no-repeat;
    background-size: 270px auto;
    padding: 28px 23px 1px 23px;
    position: relative;
    @include breakpoint(mobile) {
        padding-bottom: 100px;
        background-size: 200px auto;
    }
    &__title {
        margin: 0;
        padding: 0;
        font-family: $pop;
        font-size: 22px;
        line-height: 32px;
        max-width: 307px;
    }
    .btn {
        position: absolute;
        right: 17px;
        bottom: 29px;    
        @include breakpoint(mobile) {
            right: auto;
            left: 23px;
        }
    }
    &__person {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 22px 0 23px 0;
        img {
            width: 55px;
        }
        > div {
            width: calc(100% - 70px);
            h4 {
                font-family: $pop;
                font-size: 16px;
                margin: 0;
            }
            p {
                margin: 0px 0 -6px 0;
                font-size: 15px;
                line-height: 22px;
                color: #565656;
                a {
                    color: #1fa6fd;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.popup-house {
    position: fixed;
    overflow: auto;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(40,40,40,0.77);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    @include breakpoint(mobile) {
        display: block;
        padding: 50px 0 30px 0;
    }
    &.open {
        opacity: 1;
        visibility: visible;
    }
    .close {
        position: absolute;
        right: 4px;
        top: -40px;
        width: 14px;
        height: 14px;
        &::after,
        &::before {
            background: #FFF;
            content: "";
            width: 3px;
            height: 30px;
            position: absolute;
        }
        &::after {
            transform: rotate(45deg);
        }
        &::before {
            transform: rotate(-45deg);
        }
    }
    &__inner {
        background: #FFF;
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 128px 70px 90px 57px;
        @include breakpoint(laptop) {
            padding: 60px 30px 60px 30px;
        }
        @include breakpoint(mobile) {
            padding: 20px 15px;
        }
    }
    &__left {
        width: 48%;
        text-align: center;
        @include breakpoint(laptop) {
            width: 40%;
        }
        @include breakpoint(tablet) {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    &__right {
        width: 48%;
        @include breakpoint(laptop) {
            width: 58%;
        }
        @include breakpoint(tablet) {
            width: 100%;
            margin-top: 10px;
        }
    }
    &__title {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-heading {
            font-size: 24px;
            margin: 17px 0 0 0;
            font-weight: normal;
            @include breakpoint(mobile) {
                width: 100%;
            }
            strong {
                font-weight: 600;
            }
            .status {
                border: 2px solid transparent;
                text-transform: uppercase;
                padding: 4px 8px;
                display: inline-block;
                vertical-align: middle;
                font-size: 11px;
                font-weight: bold;
                margin: -4px 0 0 19px;
                @include breakpoint(mobileonly) {
                    margin: 5px 0 0 0;
                    display: block;
                    max-width: 100px;
                    text-align: center;
                }
                &.free {
                    border-color: #1ebdfe;
                    color: #1ebdfe;
                }
                &.sold {
                    border-color: #ff6b6b;
                    color: #ff6b6b;
                }
            }
        }
        .price {
            margin: -17px 32px 23px auto;
            white-space: nowrap;
            @include breakpoint(mobile) {
                margin: 20px auto 0 0;
            }
            strong {
                font-family: $pop;
                font-weight: bold;
                font-size: 30px;
                line-height: 24px;
            }
            span {
                display: block;
                font-family: $pop;
                font-weight: bold;
                font-size: 18px;
                margin: 10px 0 0 0;
            }
        }
    }
    .desc-list {
        display: flex;
        flex-wrap: wrap;
        margin: 29px 0 30px 0;
        justify-content: space-between;
        &__item {
            margin: 0 0 19px 0;
            width: 47%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            border-bottom: 1px solid #e1e1e1;
            padding: 0 0 12px 0;
            @include breakpoint(mobileonly) {
                width: 100% !important;
            }
            .left {
                width: 56%;
            }
            .right {
                width: 44%;
                font-weight: bold;
            }
        }
    }
    .popup-house-slider {
        margin: 0 0 46px 0;
        position: relative;
        img {
            display: inline-block !important;
            max-width: 80%;
        }
        .slick-prev,
        .slick-next {
            position: absolute;
            top: 50%;
            width: 21px;
            height: 36px;
            text-indent: -9999px;
            transform: translateY(-50%);
            background: none;
            border: none;
            cursor: pointer;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            z-index: 10;
        }
        .slick-prev {
            left: 0;
            background-image: url("../images/svg/back.svg");
        }
        .slick-next {
            right: 0;
            background-image: url("../images/svg/next.svg");
        }
    }
}

.tooltip-house {
    position: absolute;
    background: rgba(0,0,0,1);
    width: 210px;
    color: #FFF;
    padding: 17px 20px;
    display: none;
    transform: translateY(calc(-190% - 40px)) translateX(-50%);
    h2 {
        margin: 0 0 15px 0;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
    }
    p {
        font-size: 14px;
        margin: -10px 0 0 0;
    }
    &::after {
        position: absolute;
        bottom: -8px;
        margin-left: -4px;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid rgba(0,0,0,1);
        content: "";
        transform: rotate(180deg);
    }
    .status {
        border: 2px solid transparent;
        text-transform: uppercase;
        padding: 4px 8px;
        display: inline-block;
        vertical-align: middle;
        font-size: 11px;
        font-weight: bold;
        margin: 0 0 10px 0;
        &.free {
            border-color: #1ebdfe;
            color: #1ebdfe;
        }
        &.sold {
            border-color: #ff6b6b;
            color: #ff6b6b;
        }
        @include breakpoint(mobileonly) {
            margin: 5px 0 0 0;
            display: block;
            max-width: 100px;
            text-align: center;
        }
    }
}

[data-popup] {
    cursor: pointer;
}