.navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding: 29px 50px 30px 48px;
    transition: all 0.3s;
    @include breakpoint(monitor) {
        padding: 29px 25px 30px 25px;
    }
    @include breakpoint(laptop) {
        padding: 29px 20px 24px 20px;
    }
    @include breakpoint(laptop) {
        background: #FFF;
        box-shadow: 0 0 20px rgba(0,0,0,0.2);
    }
    &.color,
    &.fixed {
        background: #FFF;
        box-shadow: 0 0 20px rgba(0,0,0,0.2);
        .navigation__menu {
            a {
                color: #000;
            }
        }
    }
    .btn-back {
        @include breakpoint(mobileonly) {
            padding-left: 15px;
            padding-right: 15px;
        }
        span {
            @include breakpoint(mobileonly) {
                display: none;
            }
        }
    }
    .menu-toggle {
        display: none;
        @include breakpoint(laptop) {
            display: block;
            position: absolute;
            right: 20px;
            top: 20px;
            width: 44px;
            height: 44px;
            background: #ffe42c;
            span {
                background: #282828;
                width: 28px;
                height: 5px;
                position: absolute;
                transition: all 0.3s;
                &:nth-child(1) {
                    top: 12px;
                    left: 8px;
                }
                &:nth-child(2) {
                    top: 21px;
                    left: 8px;
                }
                &:nth-child(3) {
                    top: 30px;
                    left: 8px;
                }
            }
            &.open {
                span {
                    &:nth-child(1) {
                        transform: rotate(45deg);
                        top: 19px;
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        top: 19px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .logo {
        img {
            width: 208px;
            height: 32px;
            @include breakpoint(laptop) {
                width: 165px;
                height: 27px;
            }
        }
    }
    .mobile-menu {
        display: flex;
        align-items: center;
        margin: 0 0 0 auto;
        @media all and (min-width: 1201px) {
            display: flex !important;
        }
        @include breakpoint(laptop) {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: rgba(255,255,255,0.95);
            display: none;
            padding: 20px;
            text-align: center;
        }
    }
    &__menu {
        margin: 0 53px 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        @include breakpoint(monitor) {
            margin: 0 30px 0 0;
        }
        @include breakpoint(laptop) {
            margin: 0 0 20px 0;
            display: block;
        }
        li {
            margin: 0 0 0 32px;
            @include breakpoint(monitor) {
                margin: 0 0 0 15px;
            }
            @include breakpoint(laptop) {
                margin: 0 0 10px 0;
                display: block;
                text-align: center;
            }
            &.current {
                a {
                    
                    &:after {
                        opacity: 1
                    }
                }
            }
        }
        a {
            font-family: $pop;
            font-weight: bold;
            font-size: 14px;
            color: #FFF;
            text-transform: uppercase;
            text-decoration: none;
            position: relative;
            @include breakpoint(laptop) {
                color: #000;
            }
            &::after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -10px;
                background: #1ebdfe;
                height: 4px;
                content: "";
                opacity: 0;
                transition: all 0.3s;
                @include breakpoint(laptop) {
                    display: none;
                }
            }
            &:hover,
            &.current {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}