.footer {
    background: #2e2e2e;
    padding: 40px 0;
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .copy {
            width: 100%;
            font-size: 13px;
            color: #828282;
            text-decoration: none;
            margin: 114px 0 10px 0;
            @include breakpoint(mobile) {
                margin: 35px 0 10px 0;
            }
        }
        .nav {
            margin: 0 0 0 auto;
            display: flex;
            @include breakpoint(mobile) {
                width: 100%;
                display: block;
                padding: 0;
                margin: 32px 0 0 0;
            }
            li {
                margin: 0 0 0 55px;
                @include breakpoint(mobile) {
                    margin: 0 0 6px 0;
                }
                a {
                    font-size: 13px;
                    color: #828282;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}