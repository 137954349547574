@mixin breakpoint($point) {
    @if $point == monitor {
        @media (max-width: 1500px) { @content ; }
    }
    @if $point == laptop {
        @media (max-width: 1200px) { @content ; }
    }
    @else if $point == tablet {
        @media (max-width: 1023px) { @content ; }
    }
    @else if $point == tabletup {
        @media (min-width: 1024px) { @content ; }
    }
    @else if $point == mobile {
        @media (max-width: 767px)  { @content ; }
    }
    @else if $point == mobileonly {
        @media (max-width: 600px)  { @content ; }
    }
    @else if $point == ie11 {
        @media all and (-ms-high-contrast:none) { @content ; }
    }
}

//Colors
$blue: #1b49a3;

// Fonts
$pop: 'Poppins', sans-serif;;
$lato: 'Lato', sans-serif;

//Transitions
$transition: all 0.5s;
